import styled from 'styled-components'
import Modal from 'styled-react-modal'
import { PrimaryButton, SecondaryButton } from '@designsforhealth/dfh-react-components'
import React from 'react'
import { usePageUrl } from '../../lib/pages'

const StyledModal = Modal.styled({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: 'white',
  padding: '30px',
})

const StyledSecondaryButton = styled(SecondaryButton)<{
  text?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}>`
  width: 80%;
`

const StyledPrimaryButton = styled(PrimaryButton)<{
  text?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}>`
  width: 80%;
  border: 1px solid black;
  background-color: ${({ theme }) => theme.colors.coolGray.cool250};
  color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  margin-top: 10px;
`

const StyledParagraph = styled.p`
  color: ${({ theme }) => theme.colors.grayscale.black};
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
`

interface LeaveCheckoutModalProps {
  isOpen: boolean
  toggleModal: () => void
}

const LeaveCheckoutModal: React.FC<LeaveCheckoutModalProps> = ({ isOpen, toggleModal }) => {
  const { navigate } = usePageUrl()
  return (
    <StyledModal isOpen={isOpen} onBackgroundClick={toggleModal} onEscapeKeydown={toggleModal}>
      <StyledParagraph>Are you sure you want to leave checkout?</StyledParagraph>
      <StyledSecondaryButton text="Stay in checkout" onClick={toggleModal} />
      <StyledPrimaryButton text="Continue shopping" onClick={() => navigate('/products')} />
    </StyledModal>
  )
}

export default LeaveCheckoutModal
